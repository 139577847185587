export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.authentication_token) {
    return {
      "Content-Type": "application/json",
      "X-User-Email": user.email,
      "X-User-Token": user.authentication_token,
    };
  } else {
    return {};
  }
}
