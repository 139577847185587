export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGOUT: "USERS_LOGOUT",
  GET_ACCOUNT_REQUEST: "USERS_GET_ACCOUNT_REQUEST",
  GET_ACCOUNT_SUCCESS: "USERS_GET_ACCOUNT_SUCCESS",
  GET_ACCOUNT_FAILURE: "USERS_GET_ACCOUNT_FAILURE",
  CLICKED_FLIP_BUTTON: "USER_CLICKED_FLIP_BUTTON",
  HIT_YAW_LEFT_HOTKEY: "USER_HIT_YAW_LEFT_HOTKEY",
  HIT_YAW_RIGHT_HOTKEY: "USER_HIT_YAW_RIGHT_HOTKEY",
  DRAGGED_START_NODE: "USER_DRAGGED_START_NODE",
  DRAGGED_END_NODE: "USER_DRAGGED_END_NODE",
  DRAGGED_MIDDLE_NODE: "USER_DRAGGED_MIDDLE_NODE",
  DRAGGED_SCALE_TOP_HANDLE: "USER_DRAGGED_SCALE_TOP_HANDLE",
  DRAGGED_SCALE_BOTTOM_HANDLE: "USER_DRAGGED_SCALE_BOTTOM_HANDLE",
  DRAGGED_SCALE_RIGHT_HANDLE: "USER_DRAGGED_SCALE_RIGHT_HANDLE",
  DRAGGED_SCALE_LEFT_HANDLE: "USER_DRAGGED_SCALE_LEFT_HANDLE",
};
