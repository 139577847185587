export const globalConstants = {
  SELECT_FRAME: "GLOBAL_SELECT_FRAME",
  OPEN_PREVIEW: "GLOBAL_OPEN_PREVIEW",
  CLOSE_PREVIEW: "GLOBAL_CLOSE_PREVIEW",
  OPEN_PANO_VIEW: "GLOBAL_OPEN_PANO_VIEW",
  CLOSE_PANO_VIEW: "GLOBAL_CLOSE_PANO_VIEW",
  OPEN_SPLIT_VIEW: "GLOBAL_OPEN_SPLIT_VIEW",
  OPEN_SPLIT_VIEW_VERTICAL: "GLOBAL_OPEN_SPLIT_VIEW_VERTICAL",
  CLOSE_SPLIT_VIEW: "GLOBAL_CLOSE_SPLIT_VIEW",
  CHANGE_VIEWER_HEIGHT: "GLOBAL_CHANGE_VIEWER_HEIGHT",
  CHANGE_VIEWER_WIDTH: "GLOBAL_CHANGE_VIEWER_WIDTH",
  CHANGE_NODE_INCREMENT: "GLOBAL_CHANGE_NODE_INCREMENT",
  ACTIVATE_PAGINATION: "GLOBAL_ACTIVATE_PAGINATION",
  SET_PAGE_WINDOW: "GLOBAL_SET_PAGE_WINDOW",
  TOGGLE_RECON_PATH_VISIBILITY: "GLOBAL_TOGGLE_RECON_PATH_VISIBILITY",
};

export const SCREEN_MODES = {
  DRAWING: "SCREEN_DRAWING",
  PREVIEW: "SCREEN_PREVIEW",
  PANO_VIEW: "SCREEN_PANO_VIEW",
  SPLIT_VIEW: "SCREEN_SPLIT_VIEW",
  SPLIT_VIEW_VERTICAL: "SCREEN_SPLIT_VIEW_VERTICAL",
};
