/* global Sentry */
import {
  chunk,
  findIndex,
  findLastIndex,
  take,
  assign,
  clone,
  map,
  flatMap,
  sortBy,
  minBy,
} from "lodash";

/**
 * Pre-process VideoWalk just after loading path data.
 * @param {Object} video
 */
export const preprocessVideoWalk = (video) => {
  if (video.path) {
    initiateVideoPathFromPSMatrix(video);
  }
};

/**
 * Return clone object of VideoWalk
 * @param {Object} video
 * @return {Object}
 */
export const cloneVideoWalk = (video) => {
  return assign(clone(video), {
    path: {
      cameras: map(video.path.cameras, clone),
    },
  });
};

/**
 * Find next/previous frame that marked as render=true in videowalk path frames.
 *
 * @param {Object} video
 * @param {Number} frameIndex
 * @param {Boolean} isNext
 * @return {Number}
 */
export const calcNextNodeIndex = (video, frameIndex, isNext = true) => {
  let nextFrameIndex;

  if (isNext) {
    nextFrameIndex = findIndex(video.path.cameras, { render: true }, frameIndex + 1);
  } else {
    nextFrameIndex = findLastIndex(take(video.path.cameras, frameIndex - 1), {
      render: true,
    });
  }

  // If not found, return start or end frame index according to direction.
  if (nextFrameIndex < 0) {
    nextFrameIndex = isNext ? video.path.cameras.length - 1 : 0;
  }

  return nextFrameIndex;
};

/**
 * Find nth frame that marked as render=true in videowalk path frames.
 * @param {*} video
 * @param {*} frameIndex
 * @param {*} steps
 *
 * @return {Number} index of nth node
 */
export const calcNextNthNodeIndex = (video, frameIndex, steps, isNext = true) => {
  let lastMatchedIndex = frameIndex;
  let matched = -1;

  for (let step = 0; step < steps; step++) {
    if (isNext) {
      matched = findIndex(video.path.cameras, { render: true }, lastMatchedIndex + 1);
    } else {
      matched = findLastIndex(take(video.path.cameras, lastMatchedIndex - 1), {
        render: true,
      });
    }

    if (matched > -1) {
      lastMatchedIndex = matched;
    }
  }

  return lastMatchedIndex;
};

/**
 * Calculate closest frames from other VideoWalks
 * @param {Object} frame
 * @param {Number} currentVideoId
 * @param {Array} videos
 * @param {Number} delta
 */
export const calcNearbyFrames = (frame, videos, delta) => {
  const p0 = [frame.floorplan_x, frame.floorplan_y];
  const resultFrames = [frame];

  videos.forEach((video) => {
    if (video.id === frame.videoId) return;

    const nearest = getNearestFrame(p0, video);
    if (!nearest) {
      // If failed to find nearest node in a VideoWalk path, exclude the path in VideoWalk timelapse view.
      // Extraorinary case: when VideoWalk path has no frame to render. i.e, every frame's "render: false".
      return;
    }

    const length = VectorLength(VectorSubtract(p0, [nearest.floorplan_x, nearest.floorplan_y]));

    if (length < delta) {
      nearest.videoId = video.id;
      nearest.taken_time = video.taken_time;
      resultFrames.push(nearest);
    }
  });

  return sortBy(resultFrames, "taken_time");
};

export const getNearestFrame = (pos, video) => {
  const activeFrames = video.path.cameras.filter((frame) => frame.render);
  return minBy(activeFrames, (frame) => {
    return VectorLength(VectorSubtract(pos, [frame.floorplan_x, frame.floorplan_y]));
  });
};

/**
 * Restore yaws with ps_matrix data
 * @param {Object} video
 */
export const restoreYawsFromPSMatrix = (video) => {
  video.path.cameras.forEach((frame, i) => {
    if (frame.recon_yaw !== undefined && frame.recon_yaw !== null) {
      frame.yaw = frame.recon_yaw;
      frame.rotation = frame.recon_yaw;
    }
  });
};

export const updateFrameYaw = (video, yawDelta, frameIndex) => {
  console.dir(`Updating yaw on frame ${frameIndex} by adding ${yawDelta}`);
  video.path.cameras[frameIndex].yaw += yawDelta; // Need to invert this to how the old yaw used to be
  video.path.cameras[frameIndex].rotation += yawDelta;
};

/**
 * Merge splitted VideoWalks path into one path
 * @param {Object} video
 * @param {Array} splittedVideoWalks
 */
export const mergePathsFromSplittedVideoWalks = (originalVideo, splittedVideoWalks) => {
  const sortedVideos = sortBy(splittedVideoWalks, ["order"]);
  originalVideo.splitted = false;
  mirrorFloorplanPositions(
    originalVideo.path.cameras,
    flatMap(sortedVideos, (v) => v.path.cameras)
  );
  updateVideoWalkStartEndPoints(originalVideo);
};

/**
 * Extract paths from combined VideoWalk
 * @param {Array} videos
 * @param {Object} combinedVideoWalk
 */
export const extractPathsFromCombinedVideoWalk = (videos, combinedVideoWalk) => {
  videos.forEach((video) => {
    if (!video.hidden) return;
    video.hidden = false;
    mirrorFloorplanPositions(
      video.path.cameras,
      combinedVideoWalk.path.cameras.filter((cam) => cam.original_video_id === video.id)
    );
    updateVideoWalkStartEndPoints(video);
  });
};

/**
 * Copy only floorplan position data to keep ps_matrix data as original
 * @param {Array} camerasDst
 * @param {Array} camerasSrc
 */
export const mirrorFloorplanPositions = (camerasDst, camerasSrc) => {
  camerasDst.forEach((cam, index) => {
    cam.floorplan_x = camerasSrc[index].floorplan_x;
    cam.floorplan_y = camerasSrc[index].floorplan_y;
  });
};

/**
 * Set VideoWalk's start/end points from path
 * @param {Object} video
 */
export const updateVideoWalkStartEndPoints = (video) => {
  const {
    path: { cameras },
  } = video;
  video.start_x = cameras[0].floorplan_x;
  video.start_y = cameras[0].floorplan_y;
  video.end_x = cameras[cameras.length - 1].floorplan_x;
  video.end_y = cameras[cameras.length - 1].floorplan_y;
};

/**
 *
 * @param {Object} video
 * @param {Number} startIndex
 * @param {Number} endIndex
 */

export const spreadVideoWalk = (video, index1, index2) => {
  const startIndex = Math.min(index1, index2);
  const endIndex = Math.max(index1, index2);

  const path = video.path.cameras.slice(startIndex, endIndex + 1);
  const deltaX = (path[path.length - 1].floorplan_x - path[0].floorplan_x) / (path.length - 1);
  const deltaY = (path[path.length - 1].floorplan_y - path[0].floorplan_y) / (path.length - 1);

  path.forEach((cam, index) => {
    cam.floorplan_x = path[0].floorplan_x + deltaX * index;
    cam.floorplan_y = path[0].floorplan_y + deltaY * index;
  });
};

/**
 * Split a VideoWalk paths into pieces
 * @param {Object} video
 * @param {Number} numberToSplit
 */

export const splitVideoWalks = (video, numberToSplit) => {
  const chunkSize = Math.ceil(video.path.cameras.length / numberToSplit);
  const pathChunks = chunk(video.path.cameras, chunkSize);
  const newVideoWalks = pathChunks.map((cameras) => ({
    ...video,
    path: {
      cameras,
    },
    temp_path: true,
  }));
  video.hidden = true;
  newVideoWalks.forEach(updateVideoWalkStartEndPoints);
  return newVideoWalks;
};

/**
 * Combine VideoWalk paths into one
 * @param {Array} videos
 */
export const combineVideoWalks = (videos) => {
  const sortedVideos = sortBy(
    videos.filter((v) => v.to_combine),
    ["to_combine"]
  );

  const { start_x, start_y, taken_time } = sortedVideos[0];
  const { end_x, end_y } = sortedVideos[sortedVideos.length - 1];
  const cameras = flatMap(sortedVideos, (video) => video.path.cameras);

  // Generate a temporary VideoWalk with selected paths
  const newVideoWalk = {
    start_x,
    start_y,
    end_x,
    end_y,
    taken_time,
    temp_path: true,
    path: { cameras },
  };

  sortedVideos.forEach((video) => {
    // Use "hidden" flag to mark the selected VideoWalks which will be hidden.
    video.hidden = true;
    video.to_combine = false;
  });

  return newVideoWalk;
};

/**
 * Update a node in VideoWalk path with new position
 * @param {Object} video
 * @param {Number} frameIndex
 * @param {Number} new_x
 * @param {Number} new_y
 * @return {null}
 */
export const moveVideoNode = (video, frameIndex, new_x, new_y) => {
  if (frameIndex < 0) return false;
  const frame = video.path.cameras[frameIndex];
  frame.floorplan_x = new_x;
  frame.floorplan_y = new_y;

  return true;
};

/**
 * Update VideoWalk with custom operation.
 * https://www.pivotaltracker.com/story/show/159962335
 * @param {Object} video
 * @param {Number} frameIndex
 * @param {Number} startIndex
 * @param {Number} endIndex
 * @param {Number} new_x
 * @param {Number} new_y
 */
export const moveVideoNodeV2 = (video, frameIndex, startIndex, endIndex, new_x, new_y) => {
  if (frameIndex < 0) return false;
  if (frameIndex >= endIndex || frameIndex <= startIndex) return false;

  const frame = video.path.cameras[frameIndex];
  const frameStart = video.path.cameras[startIndex];
  const frameEnd = video.path.cameras[endIndex];
  const pp = [new_x, new_y];
  const p0 = [frameStart.floorplan_x, frameStart.floorplan_y];
  const p1 = [frameEnd.floorplan_x, frameEnd.floorplan_y];
  const p2 = [frame.floorplan_x, frame.floorplan_y];

  let ratio = VectorLength(VectorSubtract(pp, p0)) / VectorLength(VectorSubtract(p2, p0));
  let theta = DirectionAngleBetween(VectorSubtract(p2, p0), VectorSubtract(pp, p0));

  let newPt;

  for (let i = frameIndex; i >= startIndex; i--) {
    const fr = video.path.cameras[i];
    newPt = MovePoint(p0, [fr.floorplan_x, fr.floorplan_y], theta, ratio);
    fr.floorplan_x = newPt[0];
    fr.floorplan_y = newPt[1];
  }

  ratio = VectorLength(VectorSubtract(pp, p1)) / VectorLength(VectorSubtract(p2, p1));
  theta = DirectionAngleBetween(VectorSubtract(p2, p1), VectorSubtract(pp, p1));

  for (let i = frameIndex + 1; i <= endIndex; i++) {
    const fr = video.path.cameras[i];
    newPt = MovePoint(p1, [fr.floorplan_x, fr.floorplan_y], theta, ratio);
    fr.floorplan_x = newPt[0];
    fr.floorplan_y = newPt[1];
  }
  return true;
};

/**
 * Calculate yaw values of next/previous frame at current position
 * @param {Object} video
 * @param {Number} frameIndex
 */

export const calcVideoFrameInfo = (video, frameIndex) => {
  const currentFrame = video.path.cameras[frameIndex];
  const currentPosition = [currentFrame.floorplan_x, currentFrame.floorplan_y];

  const isFirstNode = frameIndex === 0;
  const isLastNode = frameIndex === video.path.cameras.length - 1;
  let rotationToPrev, rotationToNext;

  if (!isFirstNode) {
    const prevFrameIndex = calcNextNodeIndex(video, frameIndex, false);
    const prevFrame = video.path.cameras[prevFrameIndex];
    const prevPosition = [prevFrame.floorplan_x, prevFrame.floorplan_y];
    rotationToPrev = Math.atan2(
      -(prevPosition[1] - currentPosition[1]),
      prevPosition[0] - currentPosition[0]
    );
  }

  if (!isLastNode) {
    const nextFrameIndex = calcNextNodeIndex(video, frameIndex, true);
    const nextFrame = video.path.cameras[nextFrameIndex];
    const nextPosition = [nextFrame.floorplan_x, nextFrame.floorplan_y];
    rotationToNext = Math.atan2(
      -(nextPosition[1] - currentPosition[1]),
      nextPosition[0] - currentPosition[0]
    );
  }

  return {
    videoId: video.id,
    frameIndex: frameIndex,
    prev: isFirstNode ? null : rotationToPrev - currentFrame.rotation,
    next: isLastNode ? null : rotationToNext - currentFrame.rotation,
  };
};

/**
 * Recalculate VideoWalk path with new start/end point and ps_matrix data
 * Used in [Start/Stop] adjust feature.
 * @param {Object} video
 * @param {String} editingPoint
 * @param {Number} new_x
 * @param {Number} new_y
 */

export const recomputeVideoPath = (video, editingPoint, new_x, new_y) => {
  if (editingPoint !== "start" && editingPoint !== "end") {
    return false;
  }
  let p0,
    p1,
    pp = [new_x, new_y];
  if (editingPoint === "start") {
    p1 = [video.start_x, video.start_y];
    p0 = [video.end_x, video.end_y];
  } else {
    p0 = [video.start_x, video.start_y];
    p1 = [video.end_x, video.end_y];
  }

  let ratio = VectorLength(VectorSubtract(pp, p0)) / VectorLength(VectorSubtract(p1, p0));
  let theta = DirectionAngleBetween(VectorSubtract(p1, p0), VectorSubtract(pp, p0));

  let newPt = MovePoint(p0, p1, theta, ratio);
  if (editingPoint === "start") {
    video.start_x = newPt[0];
    video.start_y = newPt[1];
  }
  if (editingPoint === "end") {
    video.end_x = newPt[0];
    video.end_y = newPt[1];
  }

  video.path.cameras.forEach((pos) => {
    newPt = MovePoint(p0, [pos.floorplan_x, pos.floorplan_y], theta, ratio);
    pos.floorplan_x = newPt[0];
    pos.floorplan_y = newPt[1];
  });

  return true;
};

/**
 * Initialize VideoWalk path with ps_matrix data
 * @param {Object} video
 */
export const initiateVideoPathFromPSMatrix = (video) => {
  // Fix start/end points if they are same coordinates
  initializeStartEndPoints(video);

  video.path.cameras.forEach((cam, i) => {
    const frame = video.path.cameras[i];

    frame.floorplan_x = frame.recon_x2d;
    frame.floorplan_y = frame.recon_y2d;
    if (frame.recon_yaw !== undefined && frame.recon_yaw !== null) {
      frame.yaw = frame.recon_yaw;
      frame.rotation = frame.recon_yaw;
    }
  });
};

/**
 * If start and end points are equal points, update one of them to different.
 * This applies the distance between start and end point of VideoWalk Path to non-zero.
 * Set the distance to 0.01 (1/100 of drawing width) as a temporary.
 * This will be adjusted to the proper point by VideoWalk QA later.
 *
 * @param {Object} video
 */
const initializeStartEndPoints = (video) => {
  if (video.start_x == video.end_x && video.start_y == video.end_y) {
    video.end_x = video.start_x + 0.01;
  }
};

/**
 * Calculate x/y min/max value of VideoWalk nodes.
 * Used in [Scale] adjust function
 * @param {Object} video
 */
export const calcVideoPathFrame = (video) => {
  video.min_x = video.min_y = 100;
  video.max_x = video.max_y = -100;

  video.path.cameras.forEach((cam) => {
    video.min_x = Math.min(cam.floorplan_x, video.min_x);
    video.min_y = Math.min(cam.floorplan_y, video.min_y);
    video.max_x = Math.max(cam.floorplan_x, video.max_x);
    video.max_y = Math.max(cam.floorplan_y, video.max_y);
  });
};

/**
 * Flip VideoWalk using start/end point as axis
 * Used in [Flip] adjust function
 * @param {Object} video
 */
export const flipVideoPath = (video) => {
  const p0 = [video.start_x, video.start_y];
  const p1 = [video.end_x, video.end_y];

  let vq = VectorSubtract(p1, p0);
  const axisLength = VectorLength(vq);

  video.path.cameras.forEach((cam) => {
    let pp = [cam.floorplan_x, cam.floorplan_y];
    let vp = VectorSubtract(pp, p0);
    let _k = (2 * VectorDotProduct(vp, vq)) / axisLength / axisLength;

    cam.floorplan_x = _k * vq[0] - vp[0] + p0[0];
    cam.floorplan_y = _k * vq[1] - vp[1] + p0[1];
  });
};

/**
 * Scale VideoWalk paths
 * @param {Object} video
 * @param {Number} direction
 * @param {Number} new_x
 * @param {Number} new_y
 */
export const scaleVideoPath = (video, direction, new_x, new_y) => {
  if (direction < 1) return false;

  if (direction === 1) {
    const ratio = (video.max_y - new_y) / (video.max_y - video.min_y);

    video.start_y = video.max_y - (video.max_y - video.start_y) * ratio;
    video.end_y = video.max_y - (video.max_y - video.end_y) * ratio;

    video.path.cameras.forEach((cam) => {
      cam.floorplan_y = video.max_y - (video.max_y - cam.floorplan_y) * ratio;
    });
    video.min_y = new_y;
  }
  if (direction === 2) {
    const ratio = (new_y - video.min_y) / (video.max_y - video.min_y);

    video.start_y = (video.start_y - video.min_y) * ratio + video.min_y;
    video.end_y = (video.end_y - video.min_y) * ratio + video.min_y;

    video.path.cameras.forEach((cam) => {
      cam.floorplan_y = (cam.floorplan_y - video.min_y) * ratio + video.min_y;
    });
    video.max_y = new_y;
  }
  if (direction === 3) {
    const ratio = (video.max_x - new_x) / (video.max_x - video.min_x);

    video.start_x = video.max_x - (video.max_x - video.start_x) * ratio;
    video.end_x = video.max_x - (video.max_x - video.end_x) * ratio;

    video.path.cameras.forEach((cam) => {
      cam.floorplan_x = video.max_x - (video.max_x - cam.floorplan_x) * ratio;
    });
    video.min_x = new_x;
  }
  if (direction === 4) {
    const ratio = (new_x - video.min_x) / (video.max_x - video.min_x);

    video.start_x = (video.start_x - video.min_x) * ratio + video.min_x;
    video.end_x = (video.end_x - video.min_x) * ratio + video.min_x;

    video.path.cameras.forEach((cam) => {
      cam.floorplan_x = (cam.floorplan_x - video.min_x) * ratio + video.min_x;
    });
    video.max_x = new_x;
  }

  return true;
};

function VectorSubtract(v1, v2) {
  return [v1[0] - v2[0], v1[1] - v2[1]];
}

function VectorDotProduct(v1, v2) {
  return [v1[0] * v2[0], v1[1] * v2[1]].reduce(function (sum, v) {
    return sum + v;
  }, 0);
}

function VectorLength(v) {
  return Math.sqrt(VectorDotProduct(v, v));
}

function DirectionAngleBetween(v1, v2) {
  return Math.atan2(v1[0] * v2[1] - v1[1] * v2[0], v1[0] * v2[0] + v1[1] * v2[1]);
}

function MovePoint(p0, p1, theta, ratio) {
  return [
    Math.cos(theta) * (p1[0] - p0[0]) * ratio - Math.sin(theta) * (p1[1] - p0[1]) * ratio + p0[0],
    Math.sin(theta) * (p1[0] - p0[0]) * ratio + Math.cos(theta) * (p1[1] - p0[1]) * ratio + p0[1],
  ];
}
