import { store } from "../_helpers/store";

export const trackWorklistUser = (userEmail) => {
  if (userEmail) {
    localStorage.setItem("worklist_user_email", userEmail);
  }
};

export const trackAccount = (account) => {
  if (!account || !analytics) return; // better safe than sorry

  analytics.identify(account.user_id, {
    email: account.email,
    user_id: account.user_id,
    name: account.first_name + " " + account.last_name,
    company: account.company,
    account_tier: account.account_tier,
    worklist_user_email: getWorklistUserEmail(),
  });
};

export const trackSavingVideoWalk = ({ account, videowalk, trackStats }) => {
  console.log("saving....", trackStats);
  if (!videowalk || !analytics) return; // better safe than sorry
  analytics.track("Save VideoWalk", {
    title: "VideoWalk Adjustment Tool",
    video_walk_id: videowalk.id,
    video_walk_uuid: videowalk.uuid,
    area_id: videowalk.area_id,
    user_id: account.user_id,
    worklist_user_email: getWorklistUserEmail(),
    ...trackStats,
  });
};

export const trackEvent = (eventName, additionalProperties = {}) => {
  const { videowalk } = store.getState().videowalkReducer;

  if (!videowalk || !analytics) return; // better safe than sorry

  analytics.track(eventName, {
    title: "VideoWalk Adjustment Tool",
    video_walk_id: videowalk.id,
    video_walk_uuid: videowalk.uuid,
    worklist_user_email: getWorklistUserEmail(),
    ...additionalProperties,
  });
};

const getWorklistUserEmail = () => localStorage.getItem("worklist_user_email") || "";
