import L from "leaflet";
import { SCREEN_MODES } from "../_constants";

export const customCRSZoom = () => {
  return L.extend({}, L.CRS.Simple, {
    scale: (zoom) => zoom,
  });
};

export const calcMapSize = (width, height) => {
  const mapRatio = width * 1 + height * 1;

  return {
    width: (width / mapRatio) * 1000 || 600,
    height: (height / mapRatio) * 1000 || 400,
  };
};

export const panToFrameMarker = (leaflet, screenMode, frame, drawing) => {
  if (!leaflet) return;

  setTimeout(() => {
    let latlng;
    switch (screenMode) {
      case SCREEN_MODES.PANO_VIEW:
      case SCREEN_MODES.SPLIT_VIEW:
        latlng = [frame.floorplan_y * drawing.mapHeight, frame.floorplan_x * drawing.mapWidth];
        break;
      case SCREEN_MODES.PREVIEW:
        // Do nothing
        break;
      case SCREEN_MODES.DRAWING:
        // latlng = [drawing.mapHeight / 2, drawing.mapWidth / 2]
        break;
      default:
        latlng = [drawing.mapHeight / 2, drawing.mapWidth / 2];
        break;
    }

    leaflet.leafletElement.invalidateSize({ pan: true });

    if (latlng) {
      leaflet.leafletElement.panTo(latlng);
    }
  }, 300);
};

export const getLeafletMode = (screenMode) => {
  switch (screenMode) {
    case SCREEN_MODES.PANO_VIEW:
      return "mini-map";
    case SCREEN_MODES.SPLIT_VIEW:
      return "horizontal-split";
    case SCREEN_MODES.SPLIT_VIEW_VERTICAL:
      return "vertical-split";
    default:
      return "full-map";
  }
};

export const getFrameMode = (screenMode) => {
  switch (screenMode) {
    case SCREEN_MODES.PANO_VIEW:
      return "full";
    case SCREEN_MODES.SPLIT_VIEW:
      return "split";
    case SCREEN_MODES.SPLIT_VIEW_VERTICAL:
      return "v-split";
    default:
      return "full-map";
  }
};
