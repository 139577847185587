import React from "react";
import { useSelector } from "react-redux";

function WelcomePage() {
  const account = useSelector((state) => state.accountReducer.account);

  if (!account) return false;

  if (account.account_tier === "videowalk_qa") {
    return (
      <div className="welcome-page h-screen text-center my-64">
        <h1 className="text-orange-500 text-4xl">Welcome!</h1>
        <h2 className="text-orange-500 text-2xl">Please use the correct Cloud Factory URL.</h2>
        <h3 className="text-charcoal-500 text-xl">
          {`URL should look like: ${location.origin}/:videowalkUuid`}
        </h3>
      </div>
    );
  } else {
    return (
      <div className="welcome-page h-screen text-center my-64">
        <h1 className="text-orange-500 text-3xl">You are not authorized to use this app.</h1>
        <h2 className="text-charcoal-500 text-2xl">
          Only Cloud Factory users with videowalk_qa account tier are allowed.
        </h2>
      </div>
    );
  }
}

export { WelcomePage };
