import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Navbar } from "../_components";
import { DrawingPage } from "../DrawingPage";
import { WelcomePage } from "../WelcomePage";
import { userActions } from "../_actions";

function MainPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getAccount());
  }, [dispatch]);

  const account = useSelector((state) => state.accountReducer.account);
  if (account && account.account_tier !== "videowalk_qa") {
    toast.error("You are not authorized to use this app.");
  }

  return (
    <>
      <Navbar />
      <Route exact path="/" component={WelcomePage} />
      {account && account.account_tier === "videowalk_qa" && (
        <Route path="/:videowalkUuid/:frameNum?" component={DrawingPage} />
      )}
    </>
  );
}

export { MainPage };
