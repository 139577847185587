import React from "react";
import { useSelector } from "react-redux";
import { utcToLocal } from "../../_helpers/utils";
import { SCREEN_MODES } from "../../_constants";
import { getFrameMode } from "../../_helpers/leaflet";

function VideoWalkInfo() {
  const { videowalk, drawing, screenMode } = useSelector((state) => ({
    screenMode: state.globalReducer.screenMode,
    videowalk: state.videowalkReducer.videowalk,
    drawing: state.drawingReducer.drawing,
  }));

  if (!videowalk || !drawing) return false;

  const takenTime = videowalk.taken_time || videowalk.created_at;

  return (
    <div className={`videowalk-info ${getFrameMode(screenMode)}`}>
      <div className="inline-block bg-charcoal-100 text-white-100">
        <span className="px-4 py-2 inline-block">
          <i className="icon-Calendar-Check" /> {utcToLocal(takenTime)}
        </span>
        <span className="px-4 py-2 inline-block">
          <i className="icon-Image" /> {videowalk.filename}
        </span>
        <span className="px-4 py-2 inline-block">
          <i className="icon-Drawings" /> {drawing.name}
        </span>
        <span className="px-4 py-2 inline-block">
          <i className="icon-Storage" /> {drawing.project && drawing.project.name}
        </span>
      </div>
    </div>
  );
}

export { VideoWalkInfo };
