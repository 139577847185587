import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";

import { NEXT_NODE_HOTKEY, PREVIOUS_NODE_HOTKEY } from "../../_constants/hotkey.constants";
import { globalActions, editActions, yawActions } from "../../_actions";
import { SCREEN_MODES } from "../../_constants";
import { history } from "../../_helpers";
import { calcNextNodeIndex } from "../../_helpers/videowalk";
import { trackEvent } from "../../_helpers/analytics";

function FrameToolbar() {
  const {
    isEditingPath,
    isEditingYaw,
    frameIndex,
    videowalk,
    screenMode,
    reconPathVisible,
  } = useSelector((state) => ({
    isEditingPath: state.editReducer.isEditing,
    isEditingYaw: state.yawReducer.isEditing,
    frameIndex: state.globalReducer.frameIndex,
    videowalk: state.videowalkReducer.videowalk,
    screenMode: state.globalReducer.screenMode,
    reconPathVisible: state.globalReducer.reconPathVisible,
  }));

  const nextFrameIndex = calcNextNodeIndex(videowalk, frameIndex, true);
  const prevFrameIndex = calcNextNodeIndex(videowalk, frameIndex, false);

  const dispatch = useDispatch();

  const onClickNextNode = (useHotkey = false) => {
    trackEvent("Next Node Triggered", { useHotkey });
    history.push(`/${videowalk.uuid}/${nextFrameIndex}`);
  };

  const onClickPrevNode = (useHotKey = false) => {
    trackEvent("Previous Node Triggered", { useHotKey });
    history.push(`/${videowalk.uuid}/${prevFrameIndex}`);
  };

  useHotkeys(NEXT_NODE_HOTKEY, () => onClickNextNode(true), [videowalk.uuid, nextFrameIndex]);
  useHotkeys(PREVIOUS_NODE_HOTKEY, () => onClickPrevNode(true), [videowalk.uuid, prevFrameIndex]);

  const onClickSplitView = () => {
    dispatch(globalActions.openSplitView());
  };

  const onClickAdjustPath = () => {
    if (isEditingYaw) {
      alert("You should finish yaw editing first.");
      return;
    }
    dispatch(editActions.startEditing());
  };

  const onClickAdjustYaw = () => {
    if (isEditingPath) {
      alert("You should finish path editing first.");
      return;
    }
    dispatch(yawActions.startEditing());
  };

  const onClickToggleReconPath = () => {
    dispatch(globalActions.toggleReconPathVisibility());
  };

  const handleMessage = (e) => {
    if (e.data.type === "video_next_frame" || e.data.type === "video_prev_frame") {
      const isNext = e.data.type === "video_next_frame";
      history.push(`/${videowalk.uuid}/${isNext ? nextFrameIndex : prevFrameIndex}`);
    }
  };

  useEffect(() => {
    // Listen to the events from PanoViewer
    window.addEventListener("message", handleMessage);

    // This will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("message", handleMessage);
    };
  });

  return (
    <div className="videowalk-frame-toolbar">
      <a
        className="btn-frame-control text-white-100 text-center px-2 py-4 cursor-pointer leading-none"
        onClick={() => onClickNextNode()}
      >
        <i className="icon-ArrowBig-Right text-3xl" />
        <br />
        <span className="mt-2 block text-sm">Next Node</span>
      </a>
      <a
        className="btn-frame-control text-white-100 text-center px-2 py-4 cursor-pointer leading-none"
        onClick={() => onClickPrevNode()}
      >
        <i className="icon-ArrowBig-Left text-3xl" />
        <br />
        <span className="mt-2 block text-sm">Prev Node</span>
      </a>
      {screenMode === SCREEN_MODES.PANO_VIEW && (
        <a
          className="btn-frame-control text-white-100 text-center px-2 py-4 cursor-pointer"
          onClick={onClickSplitView}
        >
          <i className="icon-Split-View-Vertical text-3xl" />
          <br />
          <span className="mt-2 block text-sm">Split-View</span>
        </a>
      )}
      {(screenMode === SCREEN_MODES.SPLIT_VIEW ||
        screenMode === SCREEN_MODES.SPLIT_VIEW_VERTICAL) && (
        <a
          className="btn-frame-control text-white-100 text-center px-2 py-4 cursor-pointer"
          onClick={onClickAdjustPath}
          data-testid="split-screen-adjust-button"
        >
          <i className="icon-VideoWalk text-3xl" />
          <br />
          <span className="mt-2 block text-sm">Adjust Path</span>
        </a>
      )}
      {(screenMode === SCREEN_MODES.SPLIT_VIEW ||
        screenMode === SCREEN_MODES.SPLIT_VIEW_VERTICAL) && (
        <a
          className="btn-frame-control text-white-100 text-center px-2 py-4 cursor-pointer"
          onClick={onClickAdjustYaw}
          data-testid="adjust-yaw-button"
        >
          <i className="icon-Photo text-3xl" />
          <br />
          <span className="mt-2 block text-sm">Adjust Yaw</span>
        </a>
      )}
      {(screenMode === SCREEN_MODES.SPLIT_VIEW ||
        screenMode === SCREEN_MODES.SPLIT_VIEW_VERTICAL) && (
        <a
          className={`btn-frame-control text-white-100 text-center px-2 py-4 cursor-pointer ${
            reconPathVisible && "selected"
          }`}
          onClick={onClickToggleReconPath}
          data-testid="toggle-recon-path-button"
        >
          <i className="icon-Eye text-3xl" />
          <br />
          <span className="mt-2 block text-sm">Recon Path</span>
        </a>
      )}
    </div>
  );
}

export { FrameToolbar };
