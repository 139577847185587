/* eslint-disable react/no-unused-prop-types */
import config from "config";
import React from "react";
import PropTypes from "prop-types";
import { assign } from "lodash";

class PanoViewer extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.iframe.addEventListener("load", (event) => {
      this.viewerWindow = event.target.contentWindow;
      this.renderNewPhoto(this.props);
    });
  }

  componentWillReceiveProps(props) {
    if (this.viewerWindow) {
      if (
        props.imageUrl !== this.props.imageUrl ||
        props.basePath !== this.props.basePath ||
        props.yawUpdatedAt !== this.props.yawUpdatedAt
      ) {
        this.renderNewPhoto(props);
      }
    }
  }

  renderNewPhoto = (props) => {
    const origin = "*"; // this.props.viewerUrl

    let currentUser = assign({ ...props.currentUser }, { role: props.role });
    currentUser["user_id"] = currentUser.id;

    this.viewerWindow.postMessage(
      {
        ...props,
        currentUser: currentUser,
        firebaseEnv: config.appEnv,
      },
      origin
    );
  };

  render() {
    return (
      <div className="pano-viewer">
        <iframe
          src={this.props.viewerUrl}
          allowFullScreen={true}
          scrolling="no"
          width="100%"
          height="100%"
          data-testid="pano-viewer-iframe"
          ref={(e) => (this.iframe = e)}
        />
      </div>
    );
  }
}

PanoViewer.propTypes = {
  imageUrl: PropTypes.string,
  basePath: PropTypes.string,
  currentUser: PropTypes.object,
  role: PropTypes.string,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  areaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewerUrl: PropTypes.string,
  yawUpdatedAt: PropTypes.number,
  videoFrameInfo: PropTypes.object,
};

PanoViewer.defaultProps = {
  role: "admin",
  viewerUrl:
    config.appEnv === "production"
      ? "https://viewer.structionsite.com"
      : "https://viewer-staging.structionsite.com",
};

export { PanoViewer };
