import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { globalActions } from "../../_actions";
import { SCREEN_MODES } from "../../_constants";

function ResizeControl() {
  const { screenMode, viewerHeight, viewerWidth } = useSelector((state) => state.globalReducer);

  const [clicked, setClicked] = useState(false);

  const dispatch = useDispatch();

  const handleMouseMove = (e) => {
    if (clicked) {
      if (screenMode === SCREEN_MODES.SPLIT_VIEW) {
        const yPositionByPercent = (e.y / window.innerHeight) * 100;
        dispatch(globalActions.changeViewerHeight(yPositionByPercent));
      }
      if (screenMode === SCREEN_MODES.SPLIT_VIEW_VERTICAL) {
        const xPositionByPercent = 100 - (e.x / window.innerWidth) * 100;
        dispatch(globalActions.changeViewerWidth(xPositionByPercent));
      }
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseRelease);

    return function cleanup() {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseRelease);
    };
  });

  const handleMouseClick = () => {
    setClicked(true);
    const iframe = document.getElementsByTagName("iframe")[0];
    if (iframe) {
      iframe.style.pointerEvents = "none";
    }
  };

  const handleMouseRelease = () => {
    setClicked(false);
    const iframe = document.getElementsByTagName("iframe")[0];
    if (iframe) {
      iframe.style.pointerEvents = "auto";
    }
  };

  if (screenMode === SCREEN_MODES.SPLIT_VIEW) {
    return (
      <div
        className="resize-control"
        onMouseDown={handleMouseClick}
        style={{ top: viewerHeight + "%" }}
      >
        <i className="icon-arrows-sort text-2xl" />
      </div>
    );
  }

  if (screenMode === SCREEN_MODES.SPLIT_VIEW_VERTICAL) {
    return (
      <div
        className="resize-control vertical"
        onMouseDown={handleMouseClick}
        style={{ right: viewerWidth + "%" }}
      >
        <i className="icon-arrows-sort text-2xl" />
      </div>
    );
  }

  return false;
}

export { ResizeControl };
