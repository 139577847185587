export const yawConstants = {
  START_EDITING: "YAW_START_EDITING",
  STOP_EDITING: "YAW_STOP_EDITING",
  CHANGE_EDITING_TYPE: "YAW_CHANGE_EDITING_TYPE",
  FORCE_RENDER_YAW: "YAW_FORCE_RENDER_YAW",
  SELECT_FIRST_FRAME: "YAW_SELECT_FIRST_FRAME",
  SELECT_SECOND_FRAME: "YAW_SELECT_SECOND_FRAME",
  DIRECTION_LEFT: -1,
  DIRECTION_RIGHT: 1,
  YAW_CONTROL_VALUE: 0.1, // radian
};

export const YAW_EDIT_TYPES = {
  SINGLE: "YAW_EDITING_IN_SINGLE",
  RANGE: "YAW_EDITING_IN_RANGE",
  NONE: "YAW_EDITING_NONE",
};
