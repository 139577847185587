import { globalConstants, SCREEN_MODES, trackConstants } from "../_constants";
import { calcNextNthNodeIndex } from "../_helpers/videowalk";
import { trackEvent } from "../_helpers/analytics";

export const globalActions = {
  selectFrame,
  openPreview,
  closePreview,
  openPanoView,
  closePanoView,
  openSplitView,
  openSplitViewVertical,
  closeSplitView,
  changeViewerHeight,
  changeViewerWidth,
  changeNodeIncrement,
  activatePagination,
  showNextPage,
  hidePrevPage,
  toggleReconPathVisibility,
};

function selectFrame(frameIndex) {
  return (dispatch, getState) => {
    const { screenMode } = getState().globalReducer;
    dispatch({ type: globalConstants.SELECT_FRAME, frameIndex });

    if (screenMode === SCREEN_MODES.DRAWING) {
      dispatch({ type: globalConstants.OPEN_PREVIEW });
    }
  };
}

function openPreview() {
  return { type: globalConstants.OPEN_PREVIEW };
}

function closePreview() {
  return { type: globalConstants.CLOSE_PREVIEW };
}

function openPanoView() {
  return { type: globalConstants.OPEN_PANO_VIEW };
}

function closePanoView() {
  return { type: globalConstants.CLOSE_PANO_VIEW };
}

function openSplitView() {
  return { type: globalConstants.OPEN_SPLIT_VIEW };
}

function openSplitViewVertical() {
  return { type: globalConstants.OPEN_SPLIT_VIEW_VERTICAL };
}

function closeSplitView() {
  return { type: globalConstants.CLOSE_SPLIT_VIEW };
}

function changeViewerHeight(viewerHeight) {
  return { type: globalConstants.CHANGE_VIEWER_HEIGHT, viewerHeight };
}

function changeViewerWidth(viewerWidth) {
  return { type: globalConstants.CHANGE_VIEWER_WIDTH, viewerWidth };
}

function changeNodeIncrement(nodeIncrement) {
  return { type: globalConstants.CHANGE_NODE_INCREMENT, nodeIncrement };
}

function toggleReconPathVisibility() {
  return {
    type: globalConstants.TOGGLE_RECON_PATH_VISIBILITY,
  };
}

function activatePagination(active = true) {
  return (dispatch, getState) => {
    const { frameIndex } = getState().globalReducer;

    trackEvent(active ? "Hide Nodes" : "Show All");
    dispatch({ type: globalConstants.ACTIVATE_PAGINATION, pagination: active });
    dispatch({ type: globalConstants.SET_PAGE_WINDOW, pageStart: frameIndex, pageEnd: frameIndex });
    dispatch({
      type: trackConstants.NODE_ADJUST,
      editingType: active ? "NODE_INCREMENT_SHOW" : "NODE_INCREMENT_HIDE",
    });
    dispatch(showNextPage());
  };
}

function showNextPage() {
  return (dispatch, getState) => {
    const { pageStart, pageEnd, nodeIncrement } = getState().globalReducer;
    const { videowalk } = getState().videowalkReducer;

    const nextPageEnd = calcNextNthNodeIndex(videowalk, pageEnd, nodeIncrement);

    trackEvent("Increment Nodes");
    dispatch({ type: globalConstants.SET_PAGE_WINDOW, pageStart, pageEnd: nextPageEnd });
    dispatch({ type: trackConstants.NODE_ADJUST, editingType: "NODE_INCREMENT_NEXT" });
  };
}

function hidePrevPage() {
  return (dispatch, getState) => {
    const { pageStart, pageEnd, nodeIncrement } = getState().globalReducer;
    const { videowalk } = getState().videowalkReducer;

    const nextPageEnd = calcNextNthNodeIndex(videowalk, pageEnd, nodeIncrement, false);

    if (pageEnd >= pageStart) {
      trackEvent("Decrement Nodes");
      dispatch({ type: globalConstants.SET_PAGE_WINDOW, pageStart, pageEnd: nextPageEnd });
      dispatch({ type: trackConstants.NODE_ADJUST, editingType: "NODE_INCREMENT_PREV" });
    }
  };
}
