import { yawConstants, YAW_EDIT_TYPES } from "../_constants";
import moment from "moment";

const initialState = {
  isEditing: false,
  editingType: YAW_EDIT_TYPES.SINGLE,
  lastEditedAt: null,
  lastHoldClick: false,
  firstFrameIndex: -1,
  secondFrameIndex: -1,
  rangeFirstSelected: false,
};

export function yawReducer(state = initialState, action) {
  switch (action.type) {
    case yawConstants.START_EDITING:
      return {
        ...state,
        isEditing: true,
        editingVideoWalk: action.editingVideoWalk,
      };
    case yawConstants.STOP_EDITING:
      return {
        ...state,
        isDragging: false,
        isEditing: false,
        lastEditedAt: null,
      };
    case yawConstants.CHANGE_EDITING_TYPE:
      return {
        ...state,
        editingType: action.editingType,
      };
    case yawConstants.FORCE_RENDER_YAW:
      return {
        ...state,
        lastEditedAt: moment().valueOf(), // in milliseconds
      };
    case yawConstants.SELECT_FIRST_FRAME:
      return {
        ...state,
        firstFrameIndex: action.frameIndex,
        rangeFirstSelected: true,
      };
    case yawConstants.SELECT_SECOND_FRAME:
      return {
        ...state,
        secondFrameIndex: action.frameIndex,
        rangeFirstSelected: false,
      };
    default:
      return state;
  }
}
