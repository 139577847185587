import { combineReducers } from "redux";

import { authenticationReducer } from "./authentication.reducer";
import { accountReducer } from "./account.reducer";
import { globalReducer } from "./global.reducer";
import { drawingReducer } from "./drawing.reducer";
import { videowalkReducer } from "./videowalk.reducer";
import { editReducer } from "./edit.reducer";
import { yawReducer } from "./yaw.reducer";
import { trackReducer } from "./track.reducer";

const rootReducer = combineReducers({
  authenticationReducer,
  accountReducer,
  globalReducer,
  drawingReducer,
  videowalkReducer,
  editReducer,
  yawReducer,
  trackReducer,
});

export default rootReducer;
