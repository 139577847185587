export const editConstants = {
  START_EDITING: "EDIT_START_EDITING",
  STOP_EDITING: "EDIT_STOP_EDITING",
  CHANGE_EDITING_TYPE: "EDIT_CHANGE_EDITING_TYPE",
  SET_EDITING_NODE: "EDIT_SET_EDITING_NODE",
  SET_HOLD_NODE: "EDIT_SET_HOLD_NODE",
  SET_SCALE_DIRECTION: "EDIT_SET_SCALE_DIRECTION",
  SET_MOVING_NODE_TYPE: "EDIT_SET_MOVING_END_NODE",
  CLEAN_EDIT_STATUS: "EDIT_CLEAN_EDITING_VARS",
  FORCE_RENDER_EDITING_PATH: "EDIT_FORCE_RENDER_EDITING_PATH",
  FORCE_RENDER_ORIGIN_PATH: "EDIT_FORCE_RENDER_ORIGIN_PATH",
  EDIT_FINISH_SPREAD: "EDIT_FINISH_SPREAD",
};

export const EDITING_TYPES = {
  NONE: "",
  SPREAD: "PATH_EDITING_SPREAD",
  HOLD: "PATH_EDITING_HOLD",
  SINGLE: "PATH_EDITING_SINGLE",
  NODE: "PATH_EDITING_NODE",
  SCALE: "PATH_EDITING_SCALE",
  MOVE: "PATH_EDITING_START_END",
  FLIP: "PATH_EDITING_FLIP",
  RESET: "PATH_EDITING_RESET",
};
