import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { userActions } from "../_actions";

function LoginPage() {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { email, password } = inputs;
  const dispatch = useDispatch();

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (email && password) {
      dispatch(userActions.login(email, password));
    }
  }

  return (
    <div className="container mx-auto h-full flex justify-center items-center">
      <div className="w-full max-w-sm ">
        <div className="w-full flex justify-center items-center mb-6">
          <img className="w-20" src="/assets/logo.png" alt="StructionSite" />
        </div>
        <h1 className="font-hairline mb-6 text-center font-bold text-gray-500 text-xl">
          Login to VideoWalk Editing Tool
        </h1>
        <div className="border-teal p-8 border-t-12 bg-white mb-6 rounded-lg shadow-lg bg-white-900">
          <form name="form" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Email"
              />
              {submitted && !email && (
                <p className="text-danger text-xs italic">Email is required</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <input
                className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                placeholder="********"
              />
              {submitted && !password && (
                <p className="text-danger text-xs italic">Password is required.</p>
              )}
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-orange-300 hover:bg-orange-500 text-white-100 font-bold py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Log In
              </button>
            </div>
          </form>
        </div>
        <p className="text-center text-gray-500 text-md">
          &copy;2020 StructionSite, Inc. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export { LoginPage };
