import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import L from "leaflet";
import { Map, ImageOverlay } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { editActions } from "../_actions";
import { panToFrameMarker, getLeafletMode } from "../_helpers/leaflet";

import "../_styles/leaflet.css";

function FloorPlan(props) {
  const { drawing, isEditing, screenMode, videowalk, frameIndex, viewerHeight, viewerWidth } =
    useSelector((state) => ({
      drawing: state.drawingReducer.drawing,
      isEditing: state.editReducer.isEditing,
      screenMode: state.globalReducer.screenMode,
      videowalk: state.videowalkReducer.videowalk,
      frameIndex: state.globalReducer.frameIndex,
      viewerHeight: state.globalReducer.viewerHeight,
      viewerWidth: state.globalReducer.viewerWidth,
    }));

  useEffect(() => {
    const frame = videowalk.path.cameras[frameIndex];

    panToFrameMarker(leafletRef.current, screenMode, frame, drawing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenMode, frameIndex]);

  const leafletRef = useRef(null);
  const dispatch = useDispatch();

  const handleMouseMove = (e) => {
    if (isEditing && e.originalEvent.buttons === 1) {
      dispatch(editActions.handleMouseMove(e));
    }
  };
  const handleMouseUp = (e) => {
    e.target.dragging.enable();
    if (isEditing) {
      dispatch(editActions.cleanEditingVars());
    }
  };

  if (!drawing) return false;

  const {
    floor_plan_urls: { full_map: floorPlanUrl },
    mapWidth,
    mapHeight,
  } = drawing;

  const bounds = [
    [0, 0],
    [mapHeight, mapWidth],
  ];

  return (
    <div
      className={`drawing-container ${getLeafletMode(screenMode)}`}
      style={{ height: 100 - viewerHeight + "%", width: 100 - viewerWidth + "%" }}
    >
      <Map
        attributionControl={false}
        zoomControl={false}
        crs={L.CRS.Simple}
        scrollWheelZoom={true}
        bounds={bounds}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        maxZoom={8}
        minZoom={0}
        boxZoom={false}
        zoom={1}
        ref={leafletRef}
      >
        <ImageOverlay url={floorPlanUrl} bounds={bounds} />
        {props.children}
      </Map>
    </div>
  );
}

FloorPlan.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export { FloorPlan };
