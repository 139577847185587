import { ApolloClient, InMemoryCache } from "@apollo/client";
import config from "config";
import { authHeader } from "./auth-header";

const headers = authHeader();

const apolloClient = new ApolloClient({
  uri: `${config.apiServer}/api/graphql`,
  cache: new InMemoryCache(),
  headers,
});

export default apolloClient;
