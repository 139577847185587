import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { EDITING_TYPES } from "../../_constants/edit.constants";
import { editActions } from "../../_actions";
import { getFrameMode } from "../../_helpers/leaflet";

function VideoWalkAdjustGuide() {
  const { isEditing, editingType, screenMode } = useSelector((state) => ({
    screenMode: state.globalReducer.screenMode,
    isEditing: state.editReducer.isEditing,
    editingType: state.editReducer.editingType,
  }));

  const dispatch = useDispatch();

  if (!isEditing) return false;

  let text, onCancel, onFinish;

  if (editingType === EDITING_TYPES.SPREAD || editingType === EDITING_TYPES.HOLD) {
    text = "Hold SHIFT to select start/end points!";
  }

  if (editingType === EDITING_TYPES.SPREAD) {
    onFinish = () => dispatch(editActions.finishSpreadEdit());
    onCancel = () => dispatch(editActions.changeEditingType(EDITING_TYPES.NONE));
  }

  if (!text) return false;

  return (
    <div
      data-testid="videowalk-adjust-guides"
      className={`videowalk-adjust-guides  ${getFrameMode(screenMode)}`}
    >
      <span className="guide-text">{text}</span>
      {onFinish && (
        <a className="btn-finish" data-testid="finish-button" onClick={onFinish}>
          Finish
        </a>
      )}
      {onCancel && (
        <a className="btn-cancel" onClick={onCancel}>
          Cancel
        </a>
      )}
    </div>
  );
}

export { VideoWalkAdjustGuide };
