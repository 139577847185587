import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { PanoViewer } from "../PanoViewer";
import { VideoWalkInfo, FrameToolbar } from "./";
import { globalActions } from "../../_actions";

import { SCREEN_MODES } from "../../_constants";
import { calcVideoFrameInfo } from "../../_helpers/videowalk";
import { ResizeControl } from "../ButtonGroup/ResizeControl";
import { getFrameMode } from "../../_helpers/leaflet";

function FrameViewer() {
  const {
    screenMode,
    videowalk,
    frameIndex,
    drawing,
    account,
    viewerHeight,
    viewerWidth,
    yawUpdatedAt,
  } = useSelector((state) => ({
    screenMode: state.globalReducer.screenMode,
    viewerHeight: state.globalReducer.viewerHeight,
    viewerWidth: state.globalReducer.viewerWidth,
    frameIndex: state.globalReducer.frameIndex,
    drawing: state.drawingReducer.drawing,
    account: state.accountReducer.account,
    yawUpdatedAt: state.yawReducer.lastEditedAt,
    videowalk: state.yawReducer.isEditing
      ? state.yawReducer.editingVideoWalk
      : state.videowalkReducer.videowalk,
  }));

  const dispatch = useDispatch();

  if (
    screenMode !== SCREEN_MODES.PANO_VIEW &&
    screenMode !== SCREEN_MODES.SPLIT_VIEW &&
    screenMode !== SCREEN_MODES.SPLIT_VIEW_VERTICAL
  )
    return false;
  if (!videowalk || !drawing || !account) return false;

  const frame = videowalk.path.cameras[frameIndex];

  const videoFrameInfo = calcVideoFrameInfo(videowalk, frameIndex);

  let tilesBasePath, imageUrl;

  if (videowalk.frame_images) {
    // If small-size image exists, render it first
    imageUrl = videowalk.frame_images[frameIndex];
  } else {
    imageUrl = frame.image_url;
    tilesBasePath = videowalk.tiling_service_at && `${videowalk.tiles_base_path}/${frame.framenum}`;
  }

  const onClickClose = () => {
    dispatch(globalActions.closePanoView());
  };

  return (
    <div
      className={`videowalk-frame-viewer ${getFrameMode(screenMode)}`}
      style={{ height: viewerHeight + "%", width: viewerWidth + "%" }}
    >
      <PanoViewer
        imageUrl={imageUrl}
        basePath={tilesBasePath}
        imageType="panoramic"
        currentUser={account}
        role="admin"
        projectId={drawing.project_id}
        areaId={drawing.id}
        videoFrameInfo={videoFrameInfo}
        yawUpdatedAt={yawUpdatedAt}
      />
      <a className="close-viewer" onClick={onClickClose}>
        <i className="icon-Close-Circle" />
      </a>
      <FrameToolbar />
      <VideoWalkInfo />
      <ResizeControl />
    </div>
  );
}

export { FrameViewer };
