import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import HttpsRedirect from "react-https-redirect";
import { ApolloProvider } from "@apollo/client";
import honeybadger from "./_helpers/honeybadger";
import ErrorBoundary from "@honeybadger-io/react";

import { store } from "./_helpers";
import { App } from "./App";
import apolloClient from "./_helpers/apolloClient";

render(
  <ErrorBoundary honeybadger={honeybadger}>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <HttpsRedirect>
          <App />
        </HttpsRedirect>
      </ApolloProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("app")
);
