import moment from "moment";
import store from "./store";

/**
 * Convert UTC format string to Moment object in local timezone
 * @param {String} utcString
 */
export const utcToLocalMoment = (utcString) => {
  return moment.utc(utcString, "YYYY-MM-DD hh:mm:ss").local();
};

/**
 * Convert UTC format string to Local time string
 * @param {String} utcTime
 */
export const utcToLocal = (utcTime) => {
  return moment.utc(utcTime, "YYYY-MM-DD hh:mm:ss").local().format("ddd MMMM D, YYYY hh:mm A z");
};

/**
 * Get month format string from UTC format string. Used to group photos by month.
 * 2019-05-04 12:00:00 => May 2019
 * @param {String} utcTime
 */
export const utcToLocalMonth = (utcTime) => {
  return moment.utc(utcTime, "YYYY-MM-DD hh:mm:ss").local().format("MMMM YYYY");
};

/**
 * Get day format string from UTC format string
 * 2019-05-04 12:00:00 => Wed May 4, 2019
 * @param {String} utcTime
 */
export const utcToLocalDay = (utcTime) => {
  return moment.utc(utcTime, "YYYY-MM-DD hh:mm:ss").local().format("ddd MMMM D, YYYY");
};

/**
 * Display UTC format string to local based relative time.
 * 2019-05-04 12:00:00 => 3 months Ago
 * @param {String} utcTime
 */
export const utcToLocalPeriod = (utcTime) => {
  return moment.utc(utcTime, "YYYY-MM-DD hh:mm:ss").local().fromNow();
};

export const paginatedFrames = (pathFrames, pagination, start, end) => {
  if (pagination) {
    return pathFrames.slice(start, end + 1);
  } else {
    return pathFrames;
  }
};
