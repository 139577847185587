import config from "config";
import Honeybadger from "@honeybadger-io/js";

const honeybadgerConfig = {
  apiKey: "d8b45d58",
  environment: config.appEnv,
};

const honeybadger = Honeybadger.configure(honeybadgerConfig);

export default honeybadger;
