import { useDispatch } from "react-redux";
import config from "config";
import useInterval from "./useInterval";
import useFeatureFlag from "./useFeatureFlag";
import { videowalkActions } from "../_actions";

export default function useAutoSave() {
  const dispatch = useDispatch();
  const autoSaveEnabled = useFeatureFlag("vw_editor_auto_save");

  useInterval(() => autoSaveEnabled && dispatch(videowalkActions.autoSave()), config.autoSaveMs);
}
