import { editConstants, EDITING_TYPES } from "../_constants";
import moment from "moment";

const initialState = {
  frameIndex: 0,
  isEditing: false,
  editingType: EDITING_TYPES.NONE,
  lastSavedAt: null,
  lastEditedAt: null,
  lastHoldClick: false,
  isDragging: false,
};

export function editReducer(state = initialState, action) {
  switch (action.type) {
    case editConstants.START_EDITING:
      return {
        ...state,
        isEditing: true,
        editingType: EDITING_TYPES.NONE,
        editingVideoWalk: action.editingVideoWalk,
      };
    case editConstants.CHANGE_EDITING_TYPE:
      return {
        ...state,
        editingType: action.editingType,
        lastEditedAt: moment().unix(),
      };
    case editConstants.FORCE_RENDER_EDITING_PATH:
      return {
        ...state,
        lastEditedAt: moment().unix(),
      };
    case editConstants.FORCE_RENDER_ORIGIN_PATH:
      return {
        ...state,
        lastSavedAt: moment().unix(),
      };
    case editConstants.SET_HOLD_NODE:
      return {
        ...state,
        ...action,
      };
    case editConstants.SET_SCALE_DIRECTION:
      return {
        ...state,
        isDragging: true,
        scaleDirection: action.scaleDirection,
      };
    case editConstants.SET_MOVING_NODE_TYPE:
      return {
        ...state,
        isDragging: true,
        movingNodeType: action.movingNodeType,
      };
    case editConstants.SET_EDITING_NODE:
      return {
        ...state,
        isDragging: true,
        clickedEditingNode: action.clickedEditingNode,
      };
    case editConstants.STOP_EDITING:
      return {
        ...state,
        isDragging: false,
        isEditing: false,
      };
    case editConstants.CLEAN_EDIT_STATUS:
      return {
        ...state,
        isDragging: false,
        clickedEditingNode: null,
      };
    default:
      return state;
  }
}
