import { apiConstants } from "../_constants";

export function drawingReducer(state = {}, action) {
  switch (action.type) {
    case apiConstants.GET_DRAWING_REQUEST:
      return {
        loading: true,
      };
    case apiConstants.GET_DRAWING_SUCCESS:
      return {
        drawing: action.drawing,
      };
    case apiConstants.GET_DRAWING_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
