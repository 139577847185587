import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { utcToLocal } from "../../_helpers/utils";
import { editActions, globalActions } from "../../_actions";
import { SCREEN_MODES } from "../../_constants";

function VideoWalkPreview() {
  const videowalk = useSelector((state) => state.videowalkReducer.videowalk);
  const { frameIndex, screenMode } = useSelector((state) => ({
    screenMode: state.globalReducer.screenMode,
    frameIndex: state.globalReducer.frameIndex,
  }));
  const dispatch = useDispatch();

  if (!videowalk || screenMode !== SCREEN_MODES.PREVIEW) return false;

  const frame = videowalk.path.cameras[frameIndex];

  const imageUrl =
    (videowalk.frame_images ? videowalk.frame_images[frameIndex] : frame.image_url) +
    "?ref=preview";

  const imageStyle = {
    backgroundSize: "100% 100%",
    backgroundImage: imageUrl ? `url(${imageUrl})` : null,
  };
  const imageTakenTime = utcToLocal(videowalk.taken_time || videowalk.created_at);

  const onClickAdjust = () => {
    dispatch(editActions.startEditing());
  };

  const onClosePreview = () => {
    dispatch(globalActions.closePreview());
  };

  const onClickPreview = () => {
    dispatch(globalActions.openPanoView());
  };

  const onClickSplitView = () => {
    dispatch(globalActions.openSplitView());
  };

  const onClickSplitViewVertical = () => {
    dispatch(globalActions.openSplitViewVertical());
  };

  return (
    <div
      className="videowalk-preview h-screen bg-white-100 fixed top-0 left-0 shadow-xl pt-16"
      style={{ zIndex: 1100 }}
    >
      <a
        className="close-preview shadow-2xl bg-gray-700 text-white-100 rounded-r text-center cursor-pointer hover:bg-gray-900"
        onClick={onClosePreview}
      >
        <i className="icon-Close-Circle align-middle" />
      </a>
      <div className="frame-image w-full cursor-pointer bg-gray-300" onClick={onClickPreview}>
        <div className="h-full w-full" style={imageStyle} />
      </div>
      <div className="px-12 py-4">
        <div className="action-button-group py-4">
          <a
            className="image-adjust mx-2 align-top text-center py-2 cursor-pointer hover:text-orange-500"
            data-testid="adjust-button"
            onClick={onClickAdjust}
          >
            <i className="icon-VideoWalk text-3xl" />
            <br />
            <span className="mt-2 block">Adjust</span>
          </a>
          <a
            className="image-adjust mx-2 align-top text-center py-2 cursor-pointer hover:text-orange-500"
            onClick={onClickSplitView}
            data-testid="split-screen"
          >
            <i className="icon-Split-View-Vertical text-3xl" />
            <br />
            <span className="mt-2 block whitespace-no-wrap">Split-View</span>
          </a>
          <a
            className="image-adjust mx-2 align-top text-center py-2 cursor-pointer hover:text-orange-500"
            onClick={onClickSplitViewVertical}
            data-testid="split-screen"
          >
            <i className="icon-Split-View text-3xl" />
            <br />
            <span className="mt-2 block whitespace-no-wrap">
              Vertical <br />
              Split-View
            </span>
          </a>
        </div>
        <div className="py-6 border-t border-gray-100">
          <p>
            <i className="icon-Calendar-Check" /> <b>Date Taken</b>
          </p>
          <span className="text-gray-300"> {imageTakenTime} </span>
        </div>
        <div className="py-6 border-t border-gray-100">
          <p>
            <i className="icon-User" /> <b>Taken By</b>
          </p>
          <span className="text-gray-300">
            {videowalk.user_name} - {videowalk.user_company}
          </span>
        </div>
        <div className="py-6 border-t border-gray-100">
          <p>
            <i className="icon-Tag" /> <b>Tags</b>
          </p>
          <span className="text-gray-300">
            {videowalk.tags &&
              videowalk.tags.map((tag, index) => {
                return index === 0 ? `${tag.name}` : `, ${tag.name}`;
              })}
          </span>
        </div>
      </div>
    </div>
  );
}

export { VideoWalkPreview };
