import { globalConstants, yawConstants, trackConstants, YAW_EDIT_TYPES } from "../_constants";
import { cloneVideoWalk, updateFrameYaw, restoreYawsFromPSMatrix } from "../_helpers/videowalk";
import { trackEvent } from "../_helpers/analytics";

export const yawActions = {
  startEditing,
  stopEditing,
  changeEditingType,
  triggerYawUpdates,
  selectRangeFrames,
  restoreYaws,
};

function startEditing() {
  return (dispatch, getState) => {
    const { videowalk } = getState().videowalkReducer;

    trackEvent("Yaw Adjustment Start");

    dispatch({ type: trackConstants.INIT });
    dispatch({ type: yawConstants.START_EDITING, editingVideoWalk: cloneVideoWalk(videowalk) });
  };
}

function stopEditing() {
  return (dispatch) => {
    trackEvent("Yaw Adjustment Stop");
    dispatch({ type: yawConstants.STOP_EDITING });
    dispatch({ type: globalConstants.ACTIVATE_PAGINATION, pagination: false });
  };
}

function changeEditingType(editingType) {
  return (dispatch) => {
    dispatch({ type: trackConstants.YAW_ADJUST, editingType });
    dispatch({ type: yawConstants.CHANGE_EDITING_TYPE, editingType });
  };
}

function selectRangeFrames(frameIndex) {
  return (dispatch, getState) => {
    const { editingType, rangeFirstSelected } = getState().yawReducer;

    if (editingType === YAW_EDIT_TYPES.RANGE) {
      dispatch({
        type: rangeFirstSelected
          ? yawConstants.SELECT_SECOND_FRAME
          : yawConstants.SELECT_FIRST_FRAME,
        frameIndex,
      });
    }
  };
}

function triggerYawUpdates(direction) {
  return (dispatch, getState) => {
    const {
      editingVideoWalk,
      editingType,
      firstFrameIndex,
      secondFrameIndex,
    } = getState().yawReducer;
    const { frameIndex } = getState().globalReducer;
    const yawDelta = direction * yawConstants.YAW_CONTROL_VALUE;

    if (editingType === YAW_EDIT_TYPES.SINGLE) {
      updateFrameYaw(editingVideoWalk, yawDelta, frameIndex);
    } else if (editingType === YAW_EDIT_TYPES.RANGE) {
      if (firstFrameIndex >= 0 && secondFrameIndex >= 0) {
        const from = Math.min(firstFrameIndex, secondFrameIndex);
        const to = Math.max(firstFrameIndex, secondFrameIndex);

        for (let index = from; index <= to; index++) {
          updateFrameYaw(editingVideoWalk, yawDelta, index);
        }
      }
    }

    dispatch({ type: yawConstants.FORCE_RENDER_YAW });
  };
}

function restoreYaws() {
  return (dispatch, getState) => {
    const { editingVideoWalk } = getState().yawReducer;

    restoreYawsFromPSMatrix(editingVideoWalk);
    dispatch({ type: yawConstants.FORCE_RENDER_YAW });
  };
}
