import React from "react";
import { useSelector } from "react-redux";
import { CircleMarker, Polyline, Marker } from "react-leaflet";
import { history } from "../../_helpers";

function VideoWalkReconPath() {
  const {
    videowalk,
    drawing: { mapWidth, mapHeight },
  } = useSelector((state) => ({
    videowalk: state.videowalkReducer.videowalk,
    drawing: state.drawingReducer.drawing,
  }));

  const onClickPoint = (frameIndex, e) => {
      history.push(`/${videowalk.uuid}/${frameIndex}`);
  };
  
  const renderNodePoints = () => {
    const scaledCameraPoints = videowalk.path.cameras.map((frame) => {
      return [frame.recon_y2d * mapHeight, frame.recon_x2d * mapWidth];
    });

    return scaledCameraPoints.map((point, index) => {
      // Filter frame points that user can only see and access by checking its property - "render"
      if (!videowalk.path.cameras[index].render) return false;
      // Intentionally don't skip rendering start/end node points. If needed, uncomment the next line:
      // if (index === 0 || index === videowalk.path.cameras.length - 1) return false;

      return (
        <CircleMarker
          key={index}
          onClick={(e) => onClickPoint(index, e)}
          center={point}
          color="#fff"
          fillColor="#3F83FF"
          opacity="0.5" // slighly lower than CF points - only for visibility when both points are on the same position
          fillOpacity="0.5"
          radius={6} 
        />
      )
    });
  }

  const renderPathLine = () => {
    const scaledCameraPoints = videowalk.path.cameras.map((frame) => {
      return [frame.recon_y2d * mapHeight, frame.recon_x2d * mapWidth];
    });

    return (
      <Polyline
        positions={scaledCameraPoints.filter((point) => !!point)}
        className="testid-recon-path"
        clickable={false}
        color="#50B7DA"
        opacity="0.5"
        key={videowalk.id}
      />
    );
  };

  return (
    <>
      {renderPathLine()}
      {renderNodePoints()}
    </>
  );
}

export { VideoWalkReconPath };
