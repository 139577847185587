// Heavily inspired by the Rails app version: https://github.com/StructionSite/StructionSite_Rails/blob/9ccfb9e/frontend/src/lib/feature_flag.ts
import { useQuery, gql } from "@apollo/client";

function isEnabled(id, data) {
  const featureFlags = data?.application.featureFlags || [];
  const featureFlag = featureFlags.find((flag) => flag.id === id);

  return featureFlag?.state || false;
}

function useFeatureFlag(id) {
  const { data } = useQuery(gql`
    query FeatureFlagQuery {
      application {
        featureFlags {
          id
          state
        }
      }
    }
  `);

  return isEnabled(id, data);
}

export default useFeatureFlag;
