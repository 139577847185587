import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userActions } from "../_actions";

function Navbar() {
  const account = useSelector((state) => state.accountReducer.account);
  const drawing = useSelector((state) => state.drawingReducer.drawing);
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(userActions.logout());
  }

  return (
    <nav className="mt-0 fixed w-full pin-t h-16" style={{ zIndex: 1101 }}>
      <div className="w-full h-full flex justify-between bg-charcoal-300 py-2 px-8">
        <div className="flex items-center select-none">
          <img src="/assets/logo.png" className="w-10" />
          <h1 className="ml-4 text-orange-500 font-medium text-xl mt-2">VideoWalk Editing Tool</h1>
        </div>
        <div className="flex items-center">
          {drawing && (
            <h1 className="text-xl text-white-500 font-bold">
              <i className="icon-Drawings text-3xl align-middle" />
              <span className="ml-4 text-orange-300">{drawing.project.name}</span>
              <span className="text-gray-300">{"  >  "}</span>
              <span className="text-white-300">{drawing.name}</span>
            </h1>
          )}
        </div>
        <div className="flex justify-end">
          <a
            className="inline-block py-4 px-4 text-white-100 no-underline cursor-pointer"
            onClick={handleLogout}
          >
            Logout, {account && account.first_name}
          </a>
        </div>
      </div>
    </nav>
  );
}

export { Navbar };
