import { toast } from "react-toastify";
import { userConstants, yawConstants, EDITING_TYPES } from "../_constants";
import { userService } from "../_services";
import { history } from "../_helpers";
import { trackAccount, trackEvent } from "../_helpers/analytics";
import { editActions } from "./edit.actions";
import { yawActions } from "./yaw.actions";

export const userActions = {
  login,
  logout,
  getAccount,
  clickedFlipButton,
  hitYawLeftHotkey,
  hitYawRightHotkey,
  draggedStartNode,
  draggedEndNode,
  draggedMiddleNode,
  draggedScaleTopHandle,
  draggedScaleBottomHandle,
  draggedScaleRightHandle,
  draggedScaleLeftHandle,
};

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password).then(
      (user) => {
        toast.success("Login success!");
        dispatch(success(user));
        history.push("/");
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  toast.info("You are logged out!");
  history.push("/login");
  return { type: userConstants.LOGOUT };
}

function getAccount() {
  return (dispatch) => {
    dispatch(request({}));

    userService.getAccount().then(
      (account) => {
        dispatch(success(account));
        trackAccount(account);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request(account) {
    return { type: userConstants.GET_ACCOUNT_REQUEST, account };
  }
  function success(account) {
    return { type: userConstants.GET_ACCOUNT_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.GET_ACCOUNT_FAILURE, error };
  }
}

function clickedFlipButton() {
  return (dispatch) => {
    dispatch({ type: userConstants.CLICKED_FLIP_BUTTON });
    dispatch(editActions.changeEditingType(EDITING_TYPES.FLIP));
  };
}

function hitYawLeftHotkey() {
  return (dispatch) => {
    dispatch({ type: userConstants.HIT_YAW_LEFT_HOTKEY });
    trackEvent("Yaw left triggered", { useHotkey: true });
    dispatch(yawActions.triggerYawUpdates(yawConstants.DIRECTION_LEFT));
  };
}

function hitYawRightHotkey() {
  return (dispatch) => {
    dispatch({ type: userConstants.HIT_YAW_RIGHT_HOTKEY });
    trackEvent("Yaw right triggered", { useHotkey: true });
    dispatch(yawActions.triggerYawUpdates(yawConstants.DIRECTION_RIGHT));
  };
}

function draggedStartNode(leafletEvent) {
  return { type: userConstants.DRAGGED_START_NODE, leafletEvent };
}

function draggedEndNode(leafletEvent) {
  return { type: userConstants.DRAGGED_END_NODE, leafletEvent };
}

function draggedMiddleNode(nodeIndex, leafletEvent) {
  return { type: userConstants.DRAGGED_MIDDLE_NODE, leafletEvent, nodeIndex };
}

function draggedScaleTopHandle(leafletEvent) {
  return { type: userConstants.DRAGGED_SCALE_TOP_HANDLE, leafletEvent };
}

function draggedScaleBottomHandle(leafletEvent) {
  return { type: userConstants.DRAGGED_SCALE_BOTTOM_HANDLE, leafletEvent };
}

function draggedScaleRightHandle(leafletEvent) {
  return { type: userConstants.DRAGGED_SCALE_RIGHT_HANDLE, leafletEvent };
}

function draggedScaleLeftHandle(leafletEvent) {
  return { type: userConstants.DRAGGED_SCALE_LEFT_HANDLE, leafletEvent };
}
