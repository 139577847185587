import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { FloorPlan } from "../_components";
import { PathEditButtons, YawEditButtons, PaginationButtons } from "../_components/ButtonGroup";
import {
  FrameViewer,
  FrameActiveMarker,
  VideoWalkPreview,
  VideoWalkPath,
  VideoWalkEditingPath,
  VideoWalkReconPath,
  VideoWalkAdjustGuide,
} from "../_components/VideoWalk";

import { globalActions, videowalkActions } from "../_actions";
import useAutoSave from "../_helpers/useAutoSave";

function DrawingPage(props) {
  const isEditingPath = useSelector((state) => state.editReducer.isEditing);
  const isEditingYaw = useSelector((state) => state.yawReducer.isEditing);
  const reconPathVisible = useSelector((state) => state.globalReducer.reconPathVisible);
  const {
    match: {
      params: { videowalkUuid, frameNum },
    },
  } = props;

  const dispatch = useDispatch();

  useAutoSave();

  useEffect(() => {
    dispatch(videowalkActions.getVideoWalk(videowalkUuid));
  }, [dispatch, videowalkUuid]);

  useEffect(() => {
    dispatch(globalActions.selectFrame(parseInt(frameNum) || 0));
  }, [dispatch, frameNum]);

  const videowalk = useSelector((state) => state.videowalkReducer.videowalk);
  const drawing = useSelector((state) => state.drawingReducer.drawing);

  if (!drawing || !videowalk) return false;

  return (
    <div className="w-full h-full overflow-hidden">
      <FloorPlan>
        <FrameActiveMarker />
        <VideoWalkPath />
        <VideoWalkEditingPath />
        {reconPathVisible && <VideoWalkReconPath />}
      </FloorPlan>
      <VideoWalkAdjustGuide />
      {isEditingPath && <PathEditButtons />}
      {isEditingYaw && <YawEditButtons />}
      <PaginationButtons />
      <VideoWalkPreview />
      <FrameViewer />
    </div>
  );
}

DrawingPage.propTypes = {
  match: PropTypes.object,
};

export { DrawingPage };
