export const apiConstants = {
  GET_DRAWING_REQUEST: "GET_DRAWING_REQUEST",
  GET_DRAWING_SUCCESS: "GET_DRAWING_SUCCESS",
  GET_DRAWING_FAILURE: "GET_DRAWING_FAILURE",
  GET_VIDEOWALK_REQUEST: "GET_VIDEOWALK_REQUEST",
  GET_VIDEOWALK_SUCCESS: "GET_VIDEOWALK_SUCCESS",
  GET_VIDEOWALK_FAILURE: "GET_VIDEOWALK_FAILURE",
  UPDATE_VIDEOWALK_REQUEST: "UPDATE_VIDEOWALK_REQUEST",
  UPDATE_VIDEOWALK_SUCCESS: "UPDATE_VIDEOWALK_SUCCESS",
  UPDATE_VIDEOWALK_FAILURE: "UPDATE_VIDEOWALK_FAILURE",
};
