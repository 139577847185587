/**
 * Defined buttons for "Yaw Adjust"
 * * Single, Range, Reset, Save/Cancel
 */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { YAW_EDIT_TYPES } from "../../_constants";
import { YAW_LEFT_HOTKEY, YAW_RIGHT_HOTKEY, SAVE_HOTKEY } from "../../_constants/hotkey.constants";
import { userActions, yawActions, videowalkActions } from "../../_actions";

function YawEditButtons() {
  const editingType = useSelector((state) => state.yawReducer.editingType);
  const dispatch = useDispatch();

  useHotkeys(YAW_LEFT_HOTKEY, () => dispatch(userActions.hitYawLeftHotkey()));
  useHotkeys(YAW_RIGHT_HOTKEY, () => dispatch(userActions.hitYawRightHotkey()));

  const handleMessage = (e) => {
    if (e.data.type === "viewer_keydown") {
      if (e.data.key === YAW_LEFT_HOTKEY) {
        dispatch(userActions.hitYawLeftHotkey());
      } else if (e.data.key === YAW_RIGHT_HOTKEY) {
        dispatch(userActions.hitYawRightHotkey());
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return function cleanup() {
      window.removeEventListener("message", handleMessage);
    };
  });

  const onChangeEditingType = (newEditingType) => {
    dispatch(yawActions.changeEditingType(newEditingType));
  };

  const onClickCancel = () => {
    dispatch(yawActions.stopEditing());
  };

  const onClickSave = () => {
    dispatch(videowalkActions.updateVideoWalk());
  };

  useHotkeys(SAVE_HOTKEY, (event) => {
    event.preventDefault();
    onClickSave();
  });

  const onClickReset = () => {
    if (confirm("This migration will restore yaws from ps_matrix. Are you sure?")) {
      dispatch(yawActions.restoreYaws());
    }
  };

  return (
    <div className="videowalk-adjust-group">
      <a
        className={`btn-edit btn-single ${editingType === YAW_EDIT_TYPES.SINGLE && "btn-orange"}`}
        onClick={() => onChangeEditingType(YAW_EDIT_TYPES.SINGLE)}
      >
        Single
      </a>
      <a
        className={`btn-edit btn-range ${editingType === YAW_EDIT_TYPES.RANGE && "btn-orange"}`}
        onClick={() => onChangeEditingType(YAW_EDIT_TYPES.RANGE)}
      >
        Range
      </a>
      <a className="btn-edit btn-reset" onClick={onClickReset}>
        Reset
      </a>
      <a className="btn-edit btn-save btn-orange" data-testid="save-button" onClick={onClickSave}>
        Save
      </a>
      <a className="btn-edit btn-cancel" onClick={onClickCancel}>
        Cancel
      </a>
    </div>
  );
}

export { YawEditButtons };
