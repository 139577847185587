import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { CircleMarker, Marker } from "react-leaflet";
import { SCREEN_MODES } from "../../_constants";

class FrameActiveMarker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentYaw: 0,
      currentFov: 1,
    };

    window.addEventListener("message", this.messageHandler, false);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.messageHandler, false);
  }

  messageHandler = (e) => {
    if (e.data.type === "marzipanomoved") {
      this.lastEvent = e.data;
      if (!this.isDelaying) {
        this.isDelaying = true;
        setTimeout(() => {
          this.handleVideoFrameChange(this.lastEvent);
          this.isDelaying = false;
        }, 300);
      }
    }
  };

  handleVideoFrameChange = (event) => {
    if (event.fov !== this.prevFov) {
      this.setState({ currentFov: event.fov });
    }
    if (event.yaw !== this.prevYaw) {
      this.setState({ currentYaw: event.yaw });
    }
  };

  getMarkerIcon(rotation, fov) {
    const scale = fov / 1.5;
    const iconDiv = (
      <div
        className="marker-viewcone"
        style={{
          transform: `rotate(${rotation}rad) scaleX(${scale})`,
        }}
      />
    );
    return ReactDOMServer.renderToString(iconDiv);
  }

  renderFrameActiveMarker() {
    const {
      videowalk,
      frameIndex,
      screenMode,
      drawing: { mapWidth, mapHeight },
    } = this.props;

    const { currentYaw: deltaYaw, currentFov: fov } = this.state;

    if (
      screenMode !== SCREEN_MODES.PANO_VIEW &&
      screenMode !== SCREEN_MODES.SPLIT_VIEW &&
      screenMode !== SCREEN_MODES.SPLIT_VIEW_VERTICAL
    ) {
      return false;
    }

    const frame = videowalk.path.cameras[frameIndex];
    const scaledPoints = [frame.floorplan_y * mapHeight, frame.floorplan_x * mapWidth];

    // deltaYaw is the delta betweeen the starting yaw and the amount the viewer has been moved
    // frame.rotation is the result of combinedFrameYaw
    // Math.PI / 2 is to account for a different coordinate system from recon
    const rotation = deltaYaw + frame.rotation + Math.PI / 2;

    var selectedIcon = L.divIcon({
      html: this.getMarkerIcon(rotation, fov),
      className: "videowalk-active-marker",
      iconAnchor: [40, 40],
    });

    return <Marker icon={selectedIcon} position={scaledPoints} interactive={false} />;
  }

  renderActiveFrameCircle() {
    const {
      videowalk,
      frameIndex,
      drawing: { mapWidth, mapHeight },
    } = this.props;

    const frame = videowalk.path.cameras[frameIndex];
    const scaledPoints = [frame.floorplan_y * mapHeight, frame.floorplan_x * mapWidth];

    return (
      <CircleMarker center={scaledPoints} color="#FF6106" opacity="0.2" radius={0} weight={32} />
    );
  }

  render() {
    return (
      <>
        {this.renderFrameActiveMarker()}
        {this.renderActiveFrameCircle()}
      </>
    );
  }
}

FrameActiveMarker.propTypes = {
  videowalk: PropTypes.object.isRequired,
  drawing: PropTypes.object.isRequired,
  frameIndex: PropTypes.number,
  screenMode: PropTypes.string,
};
const mapStateToProps = (state) => ({
  videowalk: state.yawReducer.isEditing
    ? state.yawReducer.editingVideoWalk
    : state.videowalkReducer.videowalk,
  drawing: state.drawingReducer.drawing,
  frameIndex: state.globalReducer.frameIndex,
  screenMode: state.globalReducer.screenMode,
  yawUpdatedAt: state.yawReducer.lastEditedAt,
});

const mapDispatchToProps = {};

const FrameActiveMarkerHOC = connect(mapStateToProps, mapDispatchToProps)(FrameActiveMarker);

export { FrameActiveMarkerHOC as FrameActiveMarker };
