import { toast } from "react-toastify";
import { apiConstants } from "../_constants";
import { apiService } from "../_services";
import { calcMapSize } from "../_helpers/leaflet";

export const drawingActions = {
  getDrawing,
};

function getDrawing(id) {
  return (dispatch) => {
    dispatch(request({ id }));

    apiService.getDrawing(id).then(
      (drawing) => {
        preprocessDrawing(drawing);
        dispatch(success(drawing));
      },
      (error) => {
        const errorMsg = error ? error.toString() : "Drawing Not Found!";
        dispatch(failure(errorMsg));
        toast.error(errorMsg);
      }
    );
  };

  function request(drawing) {
    return { type: apiConstants.GET_DRAWING_REQUEST, drawing };
  }
  function success(drawing) {
    return { type: apiConstants.GET_DRAWING_SUCCESS, drawing };
  }
  function failure(error) {
    return { type: apiConstants.GET_DRAWING_FAILURE, error };
  }
}

function preprocessDrawing(drawing) {
  const mapSize = calcMapSize(drawing.width, drawing.height);
  drawing.mapWidth = mapSize.width;
  drawing.mapHeight = mapSize.height;
}
